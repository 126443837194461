import type { NextPage, GetStaticProps } from "next";
import Link from "next/link";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { useEffect } from "react";
import styles from "../styles/Home.module.css";

const Home: NextPage = () => {
	const router = useRouter();
	const { t } = useTranslation("common");

	useEffect(() => {
		if (process.env.NODE_ENV === "development") return;
		window.location.replace("https://rockflow.ai");
	}, []);

	return (
		<div className={styles.container}>
			<Head>
				<title>RockFlow</title>
				<meta
					name="description"
					content="RockFlow Investment a new method to invest in high-quality assets globally!"
				/>
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<main
				className={styles.main}
				style={{
					opacity: process.env.NODE_ENV === "development" ? 1 : 1,
				}}
			>
				<Link href="/" locale={router.locale == "en" ? "zh-Hans" : "en"}>
					{t("change-locale")}
				</Link>
				<span className="text-upColorGreen"></span>
				<span className="text-upColorRed"></span>
				<span className="not-italic text-[#00ADE5]"></span>
				<span className="text-[#FFCF26]"></span>
				<span className="text-[#FE7CFF]"></span>
				<span className="text-[#5BEDF7]"></span>
				<span className="text-[#8267EB]"></span>
				<span className="text-[#16FFFF]"></span>
				<span className="text-[#b7b7b7]"></span>
				<span className="text-[#F9E18A]"></span>
				<Link href="/nft/avatars" className={styles.card}>
					goto nft avatars
				</Link>
				<Link href="/nft/covers" className={styles.card}>
					goto nft covers
				</Link>
				<Link href="/policy/openAccount" className={styles.card}>
					goto policies
				</Link>
				<Link href="/login" className={styles.card}>
					Login
				</Link>
				<Link href="/activities/holiday2022" className={styles.card}>
					goto pulling new
				</Link>
				<Link href="/activities/holiday2022/reward" className={styles.card}>
					goto pulling reward
				</Link>
				<Link href="/rapyd" className={styles.card}>
					rapyd Card
				</Link>
				<Link href="/deposit" className={styles.card}>
					deposit Card
				</Link>
				<Link href="/survey/account-opening" className={styles.card}>
					Open Account
				</Link>
				<Link href="/survey/deposit" className={styles.card}>
					Deposit
				</Link>
				<Link href="/survey/address-proof" className={styles.card}>
					address Proof
				</Link>
				<Link href="/copilot?hidden_navigation_bar=1" className={styles.card}>
					copilot
				</Link>
			</main>
		</div>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale = "en" }) => ({
	props: {
		...(await serverSideTranslations(locale, ["common"])),
	},
});

export default Home;
